<template>
  <div>

                  <div class="d-flex justify-content-start align-items-center mt-5">
                    <!-- <button
                      class="btn btn-success mr-1"
                      v-b-tooltip.hover
                      title="Grafik"
                      @click="$router.push('/report/pharmacy-chart')"
                    ><i class="fas fa-chart-bar px-0"></i></button> -->
                    <button
                      class="btn btn-primary mr-1"
                      v-b-tooltip.hover
                      title="Print & Unduh PDF"
                      @click="btnPrintOnClick"
                    ><i class="fas fa-print px-0"></i></button>
                    <!-- <button
                      class="btn btn-info mr-1"
                      v-b-tooltip.hover
                      title="Download Pdf"
                      @click="btnDownloadOnClick"
                    ><i class="fas fa-file-pdf px-0"></i></button> -->
                    <button
                      class="btn btn-warning mr-1"
                      v-b-tooltip.hover
                      title="Download Excel"
                      @click="btnExcelOnClick"
                    ><i class="fas fa-file-excel px-0"></i></button>
                  </div>

                  <div
                    class="d-flex justify-content-end align-items-center mt-6"
                    v-if="dataLoad == true"
                  >
                    <span
                      style="width: 150px; color:#245590 !important;"
                      class="font-weight-bolder"
                    >Total :</span>
                    <span
                      class="font-weight-bolder"
                      style="color:#245590 !important;"
                    >{{ "Rp. " + parseInt(summary).toLocaleString("id-ID")}}</span>
                  </div>

                  <!-- Table -->
                  <b-table
                    class="mt-3"
                    striped
                    hover
                    :items="items"
                    :fields="fields"
                    :style='"white-space: nowrap"'
                  responsive
                  >
                    <template #table-colgroup="scope">
                      <col
                        v-for="field in scope.fields"
                        :key="field.key"
                        :style="{ width: field.key === 'actions' ? '10%' : '' }"
                      />
                    </template>
                    <template #cell(product_list)="data">
                      <div
                        v-for="(content, index) in data.item.detail"
                        :key="index"
                      >
                        <li>
                          {{ `${content.medicine_name} : ${content.quantity} X ${parseInt(content.amount).toLocaleString('id-ID')}` }}
                        </li>
                      </div>
                    </template>
                    <template #cell(surcharge)="data">
                      {{ "Rp " + parseInt(data.item.surcharge).toLocaleString("id-ID") }}
                    </template>
                    <template #cell(final_amount)="data">
                      {{ "Rp " + parseInt(data.item.final_amount).toLocaleString("id-ID") }}
                    </template>
                  </b-table>
                  <!-- <b-pagination
                    v-if="items.length != 0"
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    @page-click="pageOnClick"
                    class="mt-4"
                  ></b-pagination> -->
                

        <!-- PDF -->
        <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="true"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          :filename="'Laporan Pendapatan Apotik'"
          :pdf-quality="2"
          :manual-pagination="false"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="780px"
          @progress="onProgress($event)"
          @hasStartedGeneration="hasStartedGeneration()"
          @hasGenerated="hasGenerated($event)"
          ref="html2Pdf"
        >
          <section slot="pdf-content">
            <template>
              <div id="printPharmacy">
                <div class="d-flex justify-content-center align-items-center mt-3">
                  <h5>Laporan Pendapatan Apotik</h5>
                </div>

                <div
                  class="d-flex justify-content-end align-items-center mt-6"
                >
                  <span
                    style="width: 150px"
                    class="font-weight-bolder"
                  >Total :</span>
                  <!-- <span class="font-weight-bold">{{ typeof summary[0] != 'undefined' ? "Rp. " + parseInt(summary[0].final_amount).toLocaleString("id-ID") : '' }}</span> -->
                  <span class="font-weight-bolder">{{ "Rp. " + parseInt(summary).toLocaleString("id-ID")}}</span>
                  <!-- <span class="font-weight-bold">{{ summary}}</span> -->
                </div>

                <b-table
                  class="mt-3"
                  striped
                  hover
                  :items="items"
                  :fields="fieldsPrint"
                >
                  <template #table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"
                      :key="field.key"
                      :style="{ width: field.key === 'actions' ? '10%' : '' }"
                    />
                  </template>
                  <template #cell(product_list)="data">
                    <div
                      v-for="(content, index) in data.item.detail"
                      :key="index"
                    >
                      <li>
                        <span class="text-sizing">
                        {{ `${content.medicine_name} : ${content.quantity} X ${parseInt(content.amount).toLocaleString('id-ID')}` }}

                        </span>
                      </li>
                    </div>
                  </template>
                  <template #cell(surcharge)="data">
                    <span class="text-sizing">
                    {{
                      "Rp " + parseInt(data.item.surcharge).toLocaleString("id-ID")
                    }}

                    </span>
                  </template>
                  <template #cell(final_amount)="data">
                    <span class="text-sizing">
                    {{
                      "Rp " +
                      parseInt(data.item.final_amount).toLocaleString("id-ID")
                    }}

                    </span>
                  </template>
                </b-table>
              </div>
            </template>
          </section>
        </vue-html2pdf>
  </div>
</template>

<script>

import XLSX from "xlsx"
import VueHtml2pdf from "vue-html2pdf"
import module from "@/core/modules/CrudModule.js"
import validation from "@/core/modules/ValidationModule.js"
import Treeselect from "@riophae/vue-treeselect"
import "@riophae/vue-treeselect/dist/vue-treeselect.css"
import Card from "@/view/content/Card.vue"
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"
import MedicineTable from "@/view/pages/reports/PharmacyHospitalReport.vue"
import EcommerceTable from "@/view/pages/reports/EcommerceReport.vue"


export default {
   props: {
    // hasPatient: Boolean,
    // items: Array,
    // summary: Array,
    // perPage: Number,
    // currentPage: Number,
    // totalRows: Number,
    dataModal:Array,
    excel:Array,
  },

  components: {
    Card,
    Treeselect,
    VueHtml2pdf,
    MedicineTable,
    EcommerceTable
  },

  data() {
    return {
      // Filter
      filter: {
        doctor_id: "",
        patient_name: "",
        start_date: "",
        end_date: "",
      },
      // Options
      doctors: [],
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      // Table Header
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "code",
          label: "Kode",
          sortable: false,
        },
        {
          key: "display_transaction_date",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "doctor_name",
          label: "Dokter",
          sortable: false,
        },
        {
          key: "patient_name",
          label: "Pasien",
          sortable: false,
        },
        {
          key: "product_list",
          label: "Daftar Produk",
          sortable: true,
        },
        {
          key: "final_amount",
          label: "Total",
          sortable: true,
        },
        {
          key: "surcharge",
          label: "Surcharge",
          sortable: true,
        },
        {
          key: "notes",
          label: "Catatan",
          sortable: true,
        },
      ],
      fieldsPrint: [
        {
          key: "number",
          label: "#",
          thClass:"text-sizing",
          tdClass:"text-sizing"
        },
        {
          key: "code",
          label: "Kode",
          thClass:"text-sizing",
          tdClass:"text-sizing"
        },
        {
          key: "display_transaction_date",
          label: "Tanggal",
          thClass:"text-sizing",
          tdClass:"text-sizing"
        },
        {
          key: "doctor_name",
          label: "Dokter",
          thClass:"text-sizing",
          tdClass:"text-sizing"
        },
        {
          key: "patient_name",
          label: "Pasien",
          thClass:"text-sizing",
          tdClass:"text-sizing"
        },
        {
          key: "product_list",
          label: "Daftar Produk",
          thClass:"text-sizing",
          tdClass:"text-sizing"
        },
        {
          key: "final_amount",
          label: "Total",
          thClass:"text-sizing",
          tdClass:"text-sizing"
        },
        {
          key: "surcharge",
          label: "Surcharge",
          thClass:"text-sizing",
          tdClass:"text-sizing"
        },
        {
          key: "notes",
          label: "Catatan",
          thClass:"text-sizing",
          tdClass:"text-sizing"
        },
      ],
      formats: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
        { text: "300", value: 300 },
      ],
      // Table Items
      items: [],
      summary: 0,
      dataLoad: false,
      
    };
  },

  methods: {

    perPageNumber() {
      this.pagination()
    },

    pageOnClick(evt, page) {
      evt.preventDefault()
      this.currentPage = page
      this.pagination()
    },

    async pagination() {
      this.excelData = this.excel
      this.items = this.dataModal;
      this.totalRows = pagination.total;
      this.dataLoad = true;

      let a
      this.summary = 0
      for (a = 0; a < this.items.length; a++) {
          this.summary += parseInt(this.items[a].final_amount)
      }
      console.log("summary",this.summary);

    },

    async getDoctorsOption() {
      let response = await module.setTreeSelect("doctors");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.doctors = response.data;
        this.doctors.unshift({ label: "Cari Berdasar Dokter", id: "", isDisabled: true });
      }
    },

    resetFilter() {
      this.filter.start_date = ""
      this.filter.end_date = ""
      this.filter.doctor_id = ""
    },

    resetFilterPeriode() {
      this.filter = validation.clearForm(this.filter)
      this.pagination()
    },

    async filterByPeriode() {
      this.pagination();
    },

    async filterByPatient() {
      // Reset Other Filter
      this.resetFilter();
      this.pagination();
    },

    async filterByDoctor(evt) {
      this.filter.doctor_id = evt.id;
      this.pagination();
    },

    btnPrintOnClick() {
      this.$htmlToPaper('printPharmacy')
    },

    btnDownloadOnClick() {
      this.$refs.html2Pdf.generatePdf()
    },

    onProgress($event) {
      console.log("onProgress", $event)
    },

    hasStartedGeneration() {
      console.log("hasStartedGeneration")
    },

    hasGenerated($event) {
      console.log("hasGenerated", $event)
    },

    btnExcelOnClick() {
      const data = XLSX.utils.json_to_sheet(this.excelData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "Laporan Pendapatan Apotik.xlsx");
    },
  },

  watch: {
    "filter.doctor_id": function (newVal, oldVal) {
      this.filter.doctor_id = typeof newVal === "undefined" ? "" : newVal;
      this.pagination();
    },
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Laporan", route: "" },
      { title: "Laporan Pendapatan Apotik" },
    ])
    this.pagination()
    this.getDoctorsOption()
  },

}
</script>

<style>
.text-sizing{
  font-size: 10px;
}
</style>