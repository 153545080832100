<template>
  <div>
    <!-- Filter -->
    <div class="row align-items-center justify-content-start mt-n3">
      <!-- <div :class="hasPatient ? 'col-md-9' : 'col-md-6'"> -->
      <div class="col-md-6">
        <button
          class="btn mx-1 btn-timeseries"
          @click="btnActiveOnClick('days')"
          v-bind:class="{
            'btn-info': btnActive.days,
            'btn-outline-info': btnActive.days == false,
          }"
        >
          Hari Ini
        </button>
        <button
          class="btn mx-1 btn-timeseries"
          @click="btnActiveOnClick('month')"
          v-bind:class="{
            'btn-info': btnActive.month,
            'btn-outline-info': btnActive.month == false,
          }"
        >
          Bulan Ini
        </button>
        <button
          class="btn mx-1 btn-timeseries"
          @click="btnActiveOnClick('lastMonth')"
          v-bind:class="{
            'btn-info': btnActive.lastMonth,
            'btn-outline-info': btnActive.lastMonth == false,
          }"
        >
          Bulan Kemarin
        </button>
        <button
          class="btn mx-1 btn-timeseries"
          @click="btnActiveOnClick('year')"
          v-bind:class="{
            'btn-info': btnActive.year,
            'btn-outline-info': btnActive.year == false,
          }"
        >
          Tahun Ini
        </button>
        <button
          class="btn mx-1 btn-timeseries"
          v-bind:class="{
            'btn-info': btnActive.period,
            'btn-outline-info': btnActive.period == false,
          }"
          @click="btnPeriodeOnClick"
        >
          Periode
        </button>
      </div>
      <!-- <div
        class="col-md-3"
      >
        <b-input-group>
          <template #prepend>
            <b-input-group-text squared>
              <b-icon-search></b-icon-search>
            </b-input-group-text>
          </template>
          <b-form-input
            type="text"
            v-model="filter.patient_name"
            placeholder="Cari Nama Pasien"
            @keyup="filterByPatient"
          ></b-form-input>
        </b-input-group>
      </div> -->
      <div class="col-md-1">
        <b-form-select
          id="input-period"
          v-model="perPage"
          :options="formats"
          @change="pagination"
          placeholder="Per Halaman (Def. 20)"
        ></b-form-select>
      </div>
    </div>

    <div class="row justify-content-between align-items-center mt-5">
      <div class="col-md-6" v-if="onShowPeriod">
        <b-input-group>
          <b-form-input
            v-model="filter.start_date"
            type="text"
            placeholder="Tanggal Awal"
            autocomplete="off"
            readonly
          ></b-form-input>
          <b-input-group-append>
            <b-form-datepicker
              v-model="filter.start_date"
              button-only
              right
              reset-button
              locale="id"
              aria-controls="example-input"
            ></b-form-datepicker>
          </b-input-group-append>
          <b-button squared variant="light">s/d</b-button>
          <b-form-input
            v-model="filter.end_date"
            type="text"
            placeholder="Tanggal Akhir"
            autocomplete="off"
            readonly
          ></b-form-input>
          <b-input-group-append>
            <b-form-datepicker
              v-model="filter.end_date"
              button-only
              right
              reset-button
              locale="id"
              aria-controls="example-input"
            ></b-form-datepicker>
          </b-input-group-append>
          <template #append>
            <b-button squared @click="generalFilter" variant="success"
              >Cari</b-button
            >
            <b-button squared @click="resetFilter" variant="danger"
              >Reset</b-button
            >
          </template>
        </b-input-group>
        <span class="d-block d-sm-none">
          <div class="row">
            <div class="col-12 pb-1">
              <b-input-group>
                <b-form-input
                  v-model="filter.start_date"
                  type="text"
                  placeholder="Tanggal Awal"
                  autocomplete="off"
                  readonly
                ></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="filter.start_date"
                    button-only
                    right
                    reset-button
                    locale="id"
                    aria-controls="example-input"
                  ></b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </div>
            <div class="col-12 pt-0">
              <b-input-group>
                <b-button squared variant="light">s/d</b-button>
                <b-form-input
                  v-model="filter.end_date"
                  type="text"
                  placeholder="Tanggal Akhir"
                  autocomplete="off"
                  readonly
                ></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="filter.end_date"
                    button-only
                    right
                    reset-button
                    locale="id"
                    aria-controls="example-input"
                  ></b-form-datepicker>
                </b-input-group-append>
                <template #prepend>
                  <b-button squared @click="filterByPeriode" variant="success"
                    >Cari</b-button
                  >
                  <b-button squared @click="resetFilterPeriode" variant="danger"
                    >Reset</b-button
                  >
                </template>
              </b-input-group>
            </div>
          </div>
        </span>
      </div>

      <div class="col-md-6" v-if="onShowPeriod == false"></div>

      <div class="col-md-4 d-flex justify-content-end">
        <button
          class="btn btn-success mr-1"
          v-b-tooltip.hover
          title="Grafik"
          @click="$router.push('/report/payment-chart')"
        >
          Grafik <i class="fas fa-chart-bar px-0 ml-1"></i>
        </button>
        <button class="btn btn-primary mr-1" @click="btnExportOnClick">
          Ekspor <i class="fas fa-print px-0 ml-1"></i>
        </button>
      </div>

      <!-- <button
        class="btn btn-primary mr-1"
        v-b-tooltip.hover
        title="Print"
        @click="btnPrintOnClick"
      >
        <i class="fas fa-print px-0"></i>
      </button>
      <button
        class="btn btn-info mr-1"
        v-b-tooltip.hover
        title="Download Pdf"
        @click="btnDownloadOnClick"
      >
        <i class="fas fa-file-download px-0"></i>
      </button>
      <button
        class="btn btn-warning mr-1"
        v-b-tooltip.hover
        title="Download Excel"
        @click="btnExcelOnClick"
      >
        <i class="fas fa-file-excel px-0"></i>
      </button> -->
    </div>

    <div class="row justify-content-between align-items-center mt-6">
      <div class="col-md-4">
        <span
          style="width: 150px; color: #245590 !important"
          class="font-weight-bold"
          >{{ recordData }} Baris Data</span
        >
      </div>

      <div class="col-md-6 d-flex justify-content-end">
        <span
          style="width: 150px; color: #245590 !important"
          class="font-weight-bolder"
          >Total :</span
        >
        <span class="font-weight-bolder" style="color: #245590 !important">{{
          "Rp. " + parseInt(summary).toLocaleString("id-ID")
        }}</span>
      </div>

      <!-- >{{ summary.length != 0 ? "Rp. " + parseInt(summary[0].total).toLocaleString('id-ID') : '' }}{{this.sum}}</span> -->
    </div>

    <!-- Table -->
    <b-table
      striped
      class="mt-3"
      hover
      :items="items"
      :fields="fields"
      responsive="sm"
      style="white-space: nowrap"
    >
      <!-- <template #cell(product_list)="data">
        <div v-for="(content, index) in data.item.services" :key="index">
          <li>
            {{
              content.name +
              " ( " +
              content.quantity +
              " X " +
              parseInt(content.price).toLocaleString("id-ID") +
              ")"
            }}
          </li>
        </div>
      </template> -->

      <template #cell(total)="data">
        Rp
        {{ parseInt(data.item.total).toLocaleString("id-ID") }}
      </template>
      <template #cell(price)="data">
        {{
          data.item.quantity +
          " X Rp " +
          parseInt(data.item.price).toLocaleString("id-ID")
        }}
      </template>
    </b-table>
    <b-pagination
      v-if="items.length != 0"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-click="pageOnClick"
      class="mt-4"
    ></b-pagination>

    <!-- PDF -->
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :filename="'Laporan Pendapatan Obat Klinik'"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <Print :items="items" :summary="summary" v-if="dataLoaded == true" />
      </section>
    </vue-html2pdf>

    <!-- <ModalReport 
      :reportType="'payment-pharmacy-report'"
      :reportName="'Pendapatan Obat Klinik'"
    /> -->
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import Print from "@/component/reports-print/PaymentPharmacyReport.vue";
import XLSX from "xlsx";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import module from "@/core/modules/CrudModule.js";
import ModalReport from "@/component/general/ModalReport.vue";
import validation from "@/core/modules/ValidationModule.js";
import moment from "moment";

export default {
  props: {
    // hasPatient: Boolean,
    // items: Array,
    // listData: Array,
    // summary: Number,
    // perPage: Number,
    // currentPage: Number,
    // totalRows: Number
  },

  components: {
    Treeselect,
    XLSX,
    VueHtml2pdf,
    Print,
    ModalReport,
  },

  data() {
    return {
      doctors: [],
      // Filter
      filter: {
        doctor_id: "",
        patient_name: "",
        start_date: "",
        end_date: "",
      },
      // filter timeseries
      onShowPeriod: false,
      btnActive: {
        days: true,
        month: false,
        lastMonth: false,
        year: false,
        period: false,
      },
      total: 0,
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      recordData: 0,
      // Table Items
      items: [],
      excelData: [],
      dataModal: [],
      listData: [],
      summary: 0,
      dataLoaded: false,

      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        // {
        //   key: "code",
        //   label: "Kode",
        //   sortable: false,
        // },
        {
          key: "created_at_display",
          label: "Tanggal",
          sortable: true,
        },

        {
          key: "medicine_name",
          label: "Nama Obat/Alkes",
          sortable: true,
        },

        {
          key: "price",
          label: "Kuantitas & harga",
          sortable: true,
        },
        {
          key: "total",
          label: "Total",
          sortable: true,
        },
      ],
      formats: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
        { text: "300", value: 300 },
      ],
    };
  },

  methods: {
    async getDoctorsOption() {
      let response = await module.setDoctorTreeSelect("doctors");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.doctors = response.data;
        this.doctors.unshift({
          label: "Filter Berdasar Dokter",
          id: "",
          isDisabled: true,
        });
      }
    },

    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.pagination();
    },

    resetFilter() {
      this.filter.start_date = "";
      this.filter.end_date = "";
      this.filter.doctor_id = "";
      this.filter.patient_name = "";
      this.pagination();
    },

    async pagination() {
      let filterParams = `&doctor_id=${this.filter.doctor_id}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`;
      // let filterReportParams = `?doctor_id=${this.filter.doctor_id}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`
      let response = await module.paginate(
        "medicine-stock-reports",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      // let responseExcel = await module.get('medicine-stock-excel-reports', `${filterReportParams}`)
      // let responseReport = await module.get('medicine-stock-no-pagination', `${filterReportParams}`)
      // if (response.data.length != 0) {
      //   this.summary = await module.get('payments-pharmacy-reports-summary-amount', `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
      // }
      let pagination = response.meta.pagination;
      // this.excelData = responseExcel
      // this.dataModal = responseReport
      this.items = response.data;
      this.totalRows = pagination.total;
      this.recordData = pagination.total;

      // this.setDataModal(this.dataModal,this.excelData)

      let f, g;
      g = this.perPage * (this.currentPage - 1) + 1;
      for (f = 0; f < response.data.length; f++) {
        response.data[f].number = g + f;
      }

      this.summaryData();
      this.dataLoaded = true;
    },

    async btnExportOnClick() {
      SwalLoading.fire();

      let filterReportParams = `?doctor_id=${this.filter.doctor_id}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`;
      let responseExcel = await module.get(
        "medicine-stock-excel-reports",
        `${filterReportParams}`
      );
      let responseReport = await module.get(
        "medicine-stock-no-pagination",
        `${filterReportParams}`
      );

      this.excelData = responseExcel;
      this.dataModal = responseReport;

      if (responseExcel) {
        Swal.close();
        this.showModal("payment-pharmacy-report", "Pendapatan Obat Klinik");
      }
    },

    generalFilter() {
      this.pagination();
    },

    perPageNumber() {
      this.pagination();
    },

    filterByPatient() {
      this.pagination();
    },

    btnExcelOnClick() {
      const data = XLSX.utils.json_to_sheet(this.excelData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "Laporan Pendapatan Obat Klinik.xlsx");
    },

    btnPrintOnClick() {
      this.$htmlToPaper("print");
    },

    btnDownloadOnClick() {
      this.$refs.html2Pdf.generatePdf();
    },

    onProgress($event) {
      console.log("onProgress", $event);
    },

    hasStartedGeneration() {
      console.log("hasStartedGeneration");
    },

    hasGenerated($event) {
      console.log("hasGenerated", $event);
    },

    summaryData() {
      let a, b;
      this.summary = 0;
      for (a = 0; a < this.items.length; a++) {
        this.summary += parseInt(this.items[a].total);
      }
    },

    showModal(type, name) {
      this.setDataModal(this.dataModal, this.excelData);
      this.$emit("modalShow", type, name);
    },
    setDataModal(dataModal, excel) {
      this.$emit("dataModal", dataModal, excel);
    },

    btnPeriodeOnClick() {
      if (this.onShowPeriod == false) {
        this.onShowPeriod = true;
        this.btnActiveOnClick("period");
      } else {
        this.onShowPeriod = false;
        this.btnActiveOnClick("period");
      }
    },

    btnActiveOnClick(type) {
      if (type == "days") {
        // set time
        this.filter.start_date = moment()
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = true;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;

        // hide period
        this.onShowPeriod = false;
      } else if (type == "month") {
        // set time
        this.filter.start_date = moment()
          .startOf("month")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("month")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = true;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;

        // hide period
        this.onShowPeriod = false;
      } else if (type == "lastMonth") {
        // set time
        this.filter.start_date = moment()
          .subtract(1, "months")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .subtract(1, "months")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = true;
        this.btnActive.year = false;
        this.btnActive.period = false;

        // hide period
        this.onShowPeriod = false;
      } else if (type == "year") {
        // set time
        this.filter.start_date = moment()
          .startOf("year")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("year")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = true;
        this.btnActive.period = false;

        // hide period
        this.onShowPeriod = false;
      } else if (type == "period") {
        // set time
        this.filter.start_date = "";
        this.filter.end_date = "";

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = true;
      }
    },
  },

  watch: {
    "filter.doctor_id": function (newVal, oldVal) {
      if (typeof newVal === "undefined") {
        this.filter.doctor_id = "";
        this.filterByDoctor();
      }
    },
  },

  mounted() {
    // Get Options
    this.getDoctorsOption();
    // this.summary()
    this.pagination();
  },
};
</script>

<style>
</style>