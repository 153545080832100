<template>
  <div id="print">
    <div class="d-flex justify-content-center align-items-center mt-3">
      <h5>Laporan Pendapatan Obat Klinik IONA</h5>
    </div> 
    <div class="d-flex justify-content-end align-items-center mt-6 mr-6">
      <span
        style="width: 150px; "
        class="font-weight-bolder"
      >Total :</span>
      <span
        class="font-weight-bolder"
        
      >{{ "Rp. " + parseInt(summary).toLocaleString('id-ID')}}</span>
    </div>

    
    
    <!-- Table -->
    <b-table
      striped
      class="mt-3"
      hover
      :items="items"
      :fields="fields"
    >
      
       <template #cell(price)="data">
        
        {{ data.item.quantity +" X Rp "+
          parseInt(
            data.item.price
          ).toLocaleString("id-ID")
        }}
      </template> 

      <!-- <template #cell(total)="data">
        <span class="text-sizing">
          Rp {{ parseInt(data.item.services[data.item.services.length - 1].total).toLocaleString('id-ID') }}
        </span>
      </template> -->
    </b-table>

  </div>
</template>

<script>

import XLSX from 'xlsx';
import Treeselect from '@riophae/vue-treeselect'
import "@riophae/vue-treeselect/dist/vue-treeselect.css"
import module from '@/core/modules/CrudModule.js'
import validation from '@/core/modules/ValidationModule.js'


export default {

  props: {
    items: Array,
    summary: Number
  },

  components: {
    Treeselect,
    XLSX
  },

  data() {
    return {
      fields: [
        {
          key: "number",
          label: "#",
          
        },
       
        {
          key: "created_at_display",
          label: "Tanggal",
          
        },
  
        {
          key: "medicine_name",
          label: "Nama Obat/Alkes",
          
        },
        
        {
          key: "price",
          label: "Kuantitas & harga",
          
        },
        {
          key: "total",
          label: "Total",
          
        },
      ],
     
    }
  },
}
</script>

<style>
.text-sizing{
  font-size: 10px;
}
</style>