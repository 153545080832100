<template>
  <div
    id="print"
  >
    <div class="d-flex justify-content-center align-items-center mt-3">
      <h5>Laporan Pengeluaran Klinik IONA</h5>
    </div>

    <div class="d-flex justify-content-end align-items-center mt-6">
      <span style="width: 150px" class="font-weight-bold">Total Pengeluaran :</span>
      <!-- <span class="font-weight-bold">{{ "Rp. " + parseInt(summary[0].amount).toLocaleString('id-ID') }}</span> -->
      <span class="font-weight-bold">{{ "Rp. " + parseInt(summary).toLocaleString('id-ID') }}</span>
    </div>

    <!-- Table -->
    <b-table
      striped
      hover
      class="mt-3"
      :items="items"
      :fields="fields"
    >
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          
        />
      </template>
    </b-table>

  </div>
</template>

<script>

export default {

  props: {
    items: Array,
    summary: Number,
  },

  data() {
    return {
      // Table Fields
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
          thClass:"text-sizing",
          tdClass:"text-sizing"
        },
        {
          key: "display_date",
          label: "Tanggal",
          sortable: true,
          thClass:"text-sizing",
          tdClass:"text-sizing"
        },
        {
          key: "name",
          label: "Keterangan",
          sortable: true,
          thClass:"text-sizing",
          tdClass:"text-sizing"
        },
        {
          key: "expense_category_name",
          label: "Kategori",
          sortable: true,
          thClass:"text-sizing",
          tdClass:"text-sizing"
        },
        {
          key: "display_amount",
          label: "Nominal (Rp)",
          sortable: true,
          thClass:"text-sizing",
          tdClass:"text-sizing"
        },
        {
          key: "created_by_name",
          label: "Penginput",
          sortable: true,
          thClass:"text-sizing",
          tdClass:"text-sizing"
        },
      ],
    }
  },

  

}
</script>

<style>
.text-sizing{
  font-size: 10px;
}
</style>